<template>
  <Loader v-if="isRolesLoading" />
  <div v-else>
    <b-row>
      <b-col md="6" v-if="u._id !== user._id" v-for="u in users" :key="u._id">
        <b-card class="d-flex">
          <FormSelectGroup
            :label="$t('forms.labels.roles')"
            :value="u.roles"
            :options="rolesOptions"
            :multiple="true"
            @change="rs => updateUser({ u, rs })"
          />

          <b-list-group>
            <b-list-group-item
              v-for="key in ['username', 'firstName', 'lastName']"
              :key="key"
              class="px-1 py-0"
            >
              <span class="font-small-2 font-weight-bolder">
                {{ $t(`forms.labels.${key}`) }}:&nbsp;
              </span>
              <span v-if="u" class="font-small-2">
                {{ u[key] }}
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>

    <b-card :title="$t(`pages.roles.sections.create`)">
      <div class="d-flex align-items-end">
        <FormInputGroup
          v-model="clearModel.name"
          :label="$t('forms.labels.name')"
          class="mb-0"
        />
        <b-button
          :disabled="!clearModel.name"
          class="ml-2"
          v-ripple
          variant="primary"
          @click="createRole"
        >
          {{ $t('forms.buttons.create') }}
        </b-button>
      </div>
    </b-card>

    <b-card :title="$t(`pages.roles.sections.edit`)">
      <FormSelectGroup
        :key="isLoading"
        :value.sync="roleModel._id"
        :label="$t('forms.labels.roles')"
        :options="rolesOptions"
        @change="role => setRole(role)"
      />

      <FormInputGroup
        v-if="roleModel._id"
        v-model="roleModel.name"
        :label="$t('forms.labels.name')"
      />

      <b-table
        v-if="roleModel._id"
        :items="['create', 'edit', 'remove']"
        :fields="fields"
        :empty-text="$t('tables.messages.empty')"
        class="mb-1"
        tbody-class="font-small-3"
        show-empty
        responsive="lg"
        no-local-sorting
        striped
        bordered
        small
      >
        <template #cell(actions)="data">
          {{ $t(`pages.roles.${data.item}`) }}
        </template>

        <template #cell(documents)="data">
          <FormCheckboxGroup
            v-model="roleModel.privileges.documents[data.item]"
            class="mb-0 d-inline-block"
          />
        </template>

        <template #cell(templates)="data">
          <FormCheckboxGroup
            v-model="roleModel.privileges.templates[data.item]"
            class="mb-0 d-inline-block"
          />
        </template>

        <template #cell(types)="data">
          <FormCheckboxGroup
            v-model="roleModel.privileges.types[data.item]"
            class="mb-0 d-inline-block"
          />
        </template>

        <template #cell(customers)="data">
          <FormCheckboxGroup
            v-model="roleModel.privileges.customers[data.item]"
            class="mb-0 d-inline-block"
          />
        </template>

        <template #cell(events)="data">
          <FormCheckboxGroup
            v-if="roleModel.privileges.events"
            v-model="roleModel.privileges.events[data.item]"
            class="mb-0 d-inline-block"
          />
        </template>
      </b-table>

      <b-button
        v-if="roleModel._id"
        class="mr-1"
        v-ripple
        variant="primary"
        @click="updateRole"
      >
        {{ $t('forms.buttons.edit') }}
      </b-button>

      <b-button
        v-if="roleModel._id"
        v-ripple
        variant="danger"
        @click="removeRole"
      >
        {{ $t('forms.buttons.delete') }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { reactive, computed, ref } from '@vue/composition-api'
import {
  BListGroupItem,
  BListGroup,
  BRow,
  BCol,
  BButton,
  BCard,
  BLink,
  BTable,
} from 'bootstrap-vue'
import {
  FormInputGroup,
  FormSelectGroup,
  FormDatepickerGroup,
  FormTextareaGroup,
  FormFileGroup,
  FormCheckboxGroup,
} from '@/views/components/forms'
import { useRolesQuery } from '@/store/roles'
import useToast from '@/views/components/useToast'
import ActionButtons from '@/views/components/pages/ActionButtons.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import { useUsersQuery } from '@/store/users'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BLink,
    BTable,
    BListGroupItem,
    BListGroup,
    FormFileGroup,
    FormInputGroup,
    FormSelectGroup,
    FormDatepickerGroup,
    FormTextareaGroup,
    FormCheckboxGroup,
    ActionButtons,
  },
  setup() {
    const { t } = useI18nUtils()
    const toast = useToast()
    const user = computed(() => store.getters['usersStore/user'])
    const users = computed(() => store.getters['usersStore/list'])
    const _user = ref({})

    const toastsText = {
      remove: t('notifications.success.roles.remove'),
      edit: t('notifications.success.roles.edit'),
      create: t('notifications.success.roles.create'),
      userEdit: t('notifications.success.users.edit'),
    }

    const {
      data: rolesData,
      refetch: rolesRefetch,
      isLoading: isRolesLoading,
      isSuccess: isRolesSuccess,
    } = useRolesQuery.all({})

    const roles = computed(() => rolesData.value?.roles || [])
    const rolesOptions = computed(
      () =>
        roles.value?.map(role => ({
          value: role._id,
          label: role.name,
        })) || []
    )

    const clearModel = ref({
      _id: null,
      name: '',
      privileges: {
        documents: {
          create: false,
          edit: false,
          remove: false,
        },
        templates: {
          create: false,
          edit: false,
          remove: false,
        },
        types: {
          create: false,
          edit: false,
          remove: false,
        },
        customers: {
          create: false,
          edit: false,
          remove: false,
        },
        events: {
          create: false,
          edit: false,
          remove: false,
        },
      },
    })

    let roleModel = reactive({ ...clearModel.value })

    const { mutate: update } = useUsersQuery.update({
      user: computed(() => _user).value,
      options: {
        onSuccess(data) {
          if (data.success) {
            toast.success(toastsText.userEdit)
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      },
    })

    const updateUser = ({ u, rs }) => {
      _user.value = { ...u, roles: rs }
      update()
    }

    const { mutate: onCreate, isLoading: isCreateLoading } =
      useRolesQuery.create({
        role: computed(() => clearModel.value),
        options: {
          async onSuccess(data) {
            if (data.success) {
              toast.success(toastsText.create)
              await rolesRefetch.value()
            } else {
              toast.error(JSON.stringify(data.error))
            }
          },
        },
      })

    const { mutate: onUpdate, isLoading } = useRolesQuery.update({
      roleId: computed(() => roleModel._id),
      role: computed(() => roleModel),
      options: {
        async onSuccess(data) {
          if (data.success) {
            toast.success(toastsText.edit)
            await rolesRefetch.value()
            roleModel = Object.assign(roleModel, data.role)
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      },
    })

    const { mutate: onRemove } = useRolesQuery.remove({
      roleId: computed(() => roleModel._id),
      options: {
        async onSuccess(data) {
          if (data.success) {
            toast.success(toastsText.remove)
            await rolesRefetch.value()
            roleModel = Object.assign(roleModel, clearModel.value)
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      },
    })

    const setRole = id => {
      const _role = roles.value.find(r => r._id === id)
      if (_role) {
        roleModel = Object.assign(roleModel, _role)
      }
    }

    const createRole = () => {
      onCreate()
    }

    const updateRole = () => {
      onUpdate()
    }

    const removeRole = () => {
      onRemove()
    }

    const fields = [
      {
        key: 'actions',
        label: t('tables.headers.actions'),
      },
      {
        key: 'documents',
        label: t('tables.headers.documents'),
        class: 'text-center',
      },
      {
        key: 'templates',
        label: t('tables.headers.templates'),
        class: 'text-center',
      },
      {
        key: 'types',
        label: t('tables.headers.types'),
        class: 'text-center',
      },
      {
        key: 'customers',
        label: t('tables.headers.customers'),
        class: 'text-center',
      },
      {
        key: 'events',
        label: t('tables.headers.journal'),
        class: 'text-center',
      },
    ]

    return {
      fields,
      user,
      users,
      roles,
      rolesOptions,
      roleModel,
      clearModel,
      setRole,
      createRole,
      updateRole,
      removeRole,
      updateUser,
      isRolesLoading,
      isRolesSuccess,
      isLoading,
      isCreateLoading,
    }
  },
}
</script>
